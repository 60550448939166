<template>
    <div>
      <Header />
      <div class="container mx-auto border border-grey-2 rounded-lg my-10 flex flex-wrap">
        <aside class="md:h-screen top-0 p-4 w-full md:w-fit">
            <form id="form-filter">
                <div class="mb-6">
                    <label for="select-city" class="block mb-2 font-bold text-gray-900 dark:text-gray-400">Select City</label>
                    <select id="select-city" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 cursor-not-allowed" v-if="listKota.length > 0" disabled v-model="selectedIdWilayah">
                        <option v-for="rowKota in listKota" :key="rowKota.nama_wilayah" :value="rowKota.id_wilayah">{{rowKota.nama_wilayah}}</option>
                    </select>
                </div>
                <div class="mb-6" v-if="$route.params.slug == 'landadventure'">
                    <label class="block mb-2 font-bold text-gray-900 dark:text-gray-300">Select {{ textLabel }} Type</label>
                    <div class="flex flex-col gap-2">
                        <button class="text-grey-2 font-bold py-2 px-4 border border-blue-700 rounded-xl">
                            Off Road
                        </button>
                        <button class="text-grey-2 font-bold py-2 px-4 border border-blue-700 rounded-xl">
                            On Road
                        </button>
                    </div>
                </div>
            </form>
        </aside>
        <div class="flex flex-none md:flex-1 flex-col p-4 w-full">
            <div class="flex font-bold px-2">
                <label class="block font-bold text-gray-900 align-middle my-auto">Select Package</label>
                <div class="flex flex-row gap-2 mx-2">
                    <button type="button" disabled class="bg-white text-grey-2 font-bold py-1 px-4 border border-grey-2 rounded-xl cursor-not-allowed">
                        Day Trip
                    </button>
                    <button type="button" class="bg-primary text-white font-bold py-1 px-4 border border-primary rounded-xl">
                        Rental
                    </button>
                </div>
            </div>
            <div class="py-4 px-2">
                <div class="md:grid md:grid-cols-4 gap-4 px-2">
                    <div class="col-span-2 py-2" v-if="showPembayaran == 'subtotal'">
                        <div class="w-full">
                            <span class="font-extrabold text-black text-lg">
                                Base Rate
                            </span>
                            <div class="w-full">
                                <div class="my-1">
                                    <div class="flex justify-between" v-for="(book, bookidx) in $store.state.packageList" :key="bookidx">
                                        <div class="grid grid-cols-1">
                                            <span class="font-bold text-black text-sm">{{book.nama}} {{book.qty}} x {{book.harga | toCurrency}}/hour</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mt-6">
                            <span class="font-extrabold text-black text-lg">
                                Date & Duration
                            </span>
                            <div class="w-full">
                                <div class="my-1">
                                    <div class="flex justify-between">
                                        <div class="grid grid-cols-1">
                                            <span class="font-bold text-black text-sm">{{$moment($store.state.bookRental.bookingDate).format("ddd, MMMM D, YYYY")}}</span>
                                            <span class="font-bold text-black text-sm">{{$moment($store.state.bookRental.jam_awal).format("HH:mm")}} - {{$moment($store.state.bookRental.jam_akhir).format("HH:mm")}} ({{durasiBooking}} Hour)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mt-6">
                            <span class="font-extrabold text-black text-lg">
                                Dock
                            </span>
                            <div class="w-full">
                                <div class="my-1">
                                    <div class="flex justify-between">
                                        <div class="grid grid-cols-1">
                                            <span class="font-bold text-black text-sm">{{$store.state.bookRental?.selectedToko?.data_dermage?.nama_dermaga}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mt-6">
                                <div class="w-full my-8 flex flex-row" v-if="ttlVocer > 0">
                                    <div class="w-full cursor-pointer font-bold flex flex-row justify-between pl-20 md:pl-28 pr-5 md:pr-10 pt-0.5 pb-1 mx-auto" :style=[voucherStyle] @click="showPembayaran = 'voucher'">
                                        <div class="flex flex-row justify-center item-center pt-0.5 pb-1 w-full">
                                            <div class="flex flex-col gap-0">
                                                <span class="text-sm md:text-base" v-if="Object.keys(formData.voucher) == 0">We have some promo for you!</span>
                                                <span class="text-xs md:text-lg" v-else>{{formData.voucher.judul}}</span>
                                                <span class="text-xs md:text-sm" v-if="Object.keys(formData.voucher) == 0">{{ttlVocer}} voucher(s) available</span>
                                                <span class="text-xs md:text-sm" v-else-if="(ttlVocer - 1) > 0">{{(ttlVocer - 1)}} voucher(s) available</span>
                                                <span class="text-xs md:text-sm" v-else>Show more voucher</span>
                                            </div>
                                        </div>
                                        <span class="grid grid-cols-1 gap-0 content-center"><font-awesome-icon icon="fa-solid fa-angle-right" size="lg" class="text-primary" /></span>
                                    </div>
                                    <div v-if="Object.keys(formData.voucher) != 0" class="text-red-400 text-lg font-bold cursor-pointer" ><font-awesome-icon icon="fa-solid fa-trash-can" @click="formData.voucher = {}"/></div>
                                </div>
                                <div class="w-full flex flex-col gap-y-2">
                                    <div class="flex justify-between" v-if="Object.keys(formData.voucher) != 0">
                                        <span class="font-bold text-black text-lg">
                                            Voucher
                                        </span>
                                        <span class="font-bold text-primary text-lg" v-if="formData.voucher.status_cashback != '1'">
                                            - {{potonganVoucher | toCurrency}}
                                        </span>
                                    </div>
                                    <div class="flex justify-between" v-if="Object.keys(formData.voucher) != 0 && formData.voucher.status_cashback == 1">
                                        <span class="font-bold text-black text-sm">
                                            Cashback TripwePay
                                        </span>
                                        <span class="font-bold text-primary text-sm">
                                            {{tripwepayVoucherCashback | toCurrency}}
                                        </span>
                                    </div>
                                    <div class="flex justify-between" v-if="Object.keys(formData.voucher) != 0 && formData.voucher.status_cashback == 1">
                                        <span class="font-bold text-black text-sm">
                                            Cashback Point
                                        </span>
                                        <span class="font-bold text-primary text-sm">
                                            {{pointVoucherCashback | toCurrency}}
                                        </span>
                                    </div>
                                    <div class="flex justify-between">
                                        <span class="font-bold text-black text-lg">
                                            Subtotal
                                        </span>
                                        <span class="font-bold text-primary text-lg">
                                            {{subtotal | toCurrency}}
                                        </span>
                                    </div>
                                    <div class="flex justify-between" v-if="formData.tripwepoints != 0">
                                        <span class="font-bold text-black text-lg">
                                            TripwePoints
                                        </span>
                                        <span class="font-bold text-primary text-lg">
                                            - {{formData.tripwepoints | toCurrency}}
                                        </span>
                                    </div>
                                    <div class="flex justify-between" v-if="formData.paymentMode == 1">
                                        <span class="font-bold text-black text-lg">
                                            Credit Card admin fee (3.5%)
                                        </span>
                                        <span class="font-bold text-primary text-lg">
                                            {{crediCardAdminFee | toCurrency}}
                                        </span>
                                    </div>
                                    <div class="flex justify-between">
                                        <span class="font-extrabold text-black text-xl">
                                            Total Payment
                                        </span>
                                        <span class="font-extrabold text-primary text-xl">
                                            {{totalpayment | toCurrency}}
                                        </span>
                                    </div>
                                    <div class="flex flex-row justify-between" v-if="isLimitMaxCC">
                                        <span class="font-bold text-red-600 text-md">
                                            *The maximum payment using a credit card is 100 million
                                        </span>
                                    </div>
                                </div>
                        </div>
                    </div>
                    
                    <div class="col-span-2 py-2" v-else-if="showPembayaran == 'voucher'">
                        <div class=" text-center m-auto">
                            <span class="font-bold text-black text-xl">
                                Use Voucher
                            </span>
                            <span class="float-right cursor-pointer" @click="showPembayaran = 'subtotal'">
                                <font-awesome-icon icon="fa-solid fa-close" size="lg" class="text-gray" />
                            </span>
                        </div>
                        <div class="w-full px-8">
                            <div class="flex flex-row justify-between px-4 py-2 text-white mt-6" :style="voucherGreenStyle">
                                <div class="flex flex-col">
                                    <span class="font-bold text-sm">
                                        {{rekomendasiVoucher.judul}}
                                    </span>
                                    <span class="text-sm" v-if="rekomendasiVoucher.satuan=='PERSEN'">
                                        {{rekomendasiVoucher.nominal}}%
                                    </span>
                                    <span class="text-sm" v-else>
                                        {{rekomendasiVoucher.nominal | toCurrency}}
                                    </span>
                                </div>
                                <div class="flex items-center">
                                    <button type="button" class="border border-white rounded-xl px-4 py-2" @click="selectVoucher(rekomendasiVoucher)">Select</button>
                                </div>
                            </div>
                        </div>
                        <div class="border-b-8 border-grey my-4"></div>
                        <div class="w-full px-8">
                            <span class="text-sm font-bold">Add Voucher</span>
                            <div class="flex flex-col md:flex-row gap-8">
                                <input v-model="kodeVoucher" placeholder="XXXXXX" class="w-full border-b-2 border-primary p-2" />
                                <button type="button" class="border border-white font-bold rounded-xl px-12 py-3 bg-primary text-white">Add</button>
                            </div>
                        </div>
                        <div class="border-b-8 border-grey my-4"></div>
                        <div class="w-full px-8" v-if="listVoucher.length > 0">
                            <div class="flex flex-row gap-4 justify-between">
                                <div class="flex flex-row gap-4">
                                    <div class="grid grid-cols-1 content-center">
                                        <img :src="iconVoucher" alt="">
                                    </div>
                                    <div class="grid grid-cols-1">
                                        <span class="text-base md:text-xl font-bold">My Voucher</span>
                                        <span class="text-sm md:text-base text-gray-500">You can only select 1 voucher</span>
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 content-center">
                                    <font-awesome-icon icon="fa-solid fa-angle-down" size="lg" class="text-primary" />
                                </div>
                            </div>
                        </div>
                        <div class="w-full px-8 my-4 overflow-y-auto h-36">
                            <div class="flex flex-col gap-4">
                                <div class="border rounded-xl py-2 px-4 text-sm md:text-base border-black hover:bg-primary hover:text-white" :class="{'bg-primary text-white': (Object.keys(formData.voucher) != 0 && formData.voucher.id_voucher == vocer.id_voucher)}" v-for="(vocer,index) in listVoucher" :key="index">
                                    <div class="flex flex-row justify-between">
                                        <div class="flex flex-col gap cursor-pointer" @click="selectVoucher(vocer)">
                                            <!-- <span class="font-bold text-lg text-primary">{{ vocer.kode }}</span> -->
                                            <span class="font-bold">{{ vocer.judul }}</span>
                                            <span class="text-grey-500 hover:text-white">Expired date: {{$moment(vocer.tglz).format("DD MMMM YYYY")}}</span>
                                        </div>
                                        <div class="grid grid-cols-1 justify-between content-end cursor-pointer">
                                            <span class="font-bold" v-if="vocer.satuan == 'PERSEN'">{{ vocer.nominal}}%</span>
                                            <span class="font-bold" v-else>{{ vocer.nominal | toCurrency}}</span>
                                            <span class="hover:text-white" @click="showDetailVoucher(vocer)">Detail</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-2 flex-col gap-2 text-sm text-left py-2">
                        <div class="border border-grey-2 rounded-xl p-8">
                            <div>
                                <div class="flex flex-row justify-between" v-if="formData.paymentMode == 1">
                                    <span class="font-bold text-black text-xl">
                                        Credit Card admin fee (3.5%)
                                    </span>
                                    <span class="font-bold text-primary text-lg">
                                        {{crediCardAdminFee | toCurrency}}
                                    </span>
                                </div>
                                <div class="flex flex-row justify-between">
                                    <span class="font-bold text-black text-lg">
                                        Total Payment
                                    </span>
                                    <span class="font-bold text-primary text-lg">
                                        {{totalpayment | toCurrency}}
                                    </span>
                                </div>
                                <div class="flex flex-row justify-between" v-if="isLimitMaxCC">
                                    <span class="font-bold text-red-600 text-md">
                                        *The maximum payment using a credit card is 100 million rupiah
                                    </span>
                                </div>
                                <div class="font-bold text-primary text-lg mt-6 mb-2">
                                    Payment Method
                                </div>
                                <div class=" w-full grid grid-col gap-4">
                                    <CardPaymentMethod @onSelectedPayment="selectPayment" :value="2" :icon="iconBalance" paymentName="Balance" desc="" :saldo="$store.state.saldo.SaldoAktif" :showSaldo="true" :selected="(formData.paymentMode == 2)" />
                                    <!-- <CardPaymentMethod @onSelectedPayment="selectPayment" :value="0" :icon="iconBni" paymentName="Transfer" desc="Transfer funds via ATM or mBanking to BNI virtual account" :showSaldo="false" :selected="(formData.paymentMode == 0)" /> -->
                                    <!-- <CardPaymentMethod @onSelectedPayment="selectPayment" :value="4" :icon="iconBca" paymentName="Transfer Bank - BCA" desc="Transfer funds via ATM or mBanking to BCA virtual account" :showSaldo="false" :selected="(formData.paymentMode == 4)"  v-show="!statusMerchant"/> -->
                                    <!-- <CardPaymentMethod @onSelectedPayment="selectPayment" :value="5" :icon="iconPermata" paymentName="Transfer Bank - Permata" desc="Transfer funds via ATM or mBanking to Permata virtual account" :showSaldo="false" :selected="(formData.paymentMode == 5)"  v-show="!statusMerchant"/>
                                    <CardPaymentMethod @onSelectedPayment="selectPayment" :value="6" :icon="iconBri" paymentName="Transfer Bank - BRI" desc="Transfer funds via ATM or mBanking to BRI virtual account" :showSaldo="false" :selected="(formData.paymentMode == 6)" v-show="!statusMerchant"/> -->
                                    <!-- <CardPaymentMethod @onSelectedPayment="selectPayment" :value="1" :icon="iconCC" paymentName="Credit Card" desc="Transfer funds via Credit Card" :showSaldo="false" :selected="(formData.paymentMode == 1)" v-show="!isLimitMaxCC && !statusMerchant"/>
                                    <CardPaymentMethod @onSelectedPayment="selectPayment" :value="41" :icon="iconCC" paymentName="Bank Transfer" desc="Transfer funds via ATM or mBanking" :showSaldo="false" :selected="(formData.paymentMode == 41)" v-show="statusMerchant"/> -->
                                    <CardPaymentMethod @onSelectedPayment="selectPayment" :value="99" :icon="iconOther" paymentName="Other Method" desc="" :showSaldo="false" :selected="(formData.paymentMode == 99)"/>
                                </div>
                                <div class="flex mt-4">
                                    <button type="button" class="bg-white underline text-primary font-bold py-1 px-10 border border-grey-2 rounded-xl text-md mx-auto" @click="handleCancelation">
                                        Cancelation Policy
                                    </button>
                                </div>
                                <div class="flex mt-4">
                                    <button type="button" class="bg-primary text-white font-bold py-2 border border-grey-2 rounded-xl text-xl mx-auto px-10" @click="handleBook">Booking Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <modal-pin ref="modalPin"></modal-pin>
      <cancelation-policy-dialog ref="cancelationPolicyDialog"></cancelation-policy-dialog>
      <detail-voucher ref="detailVoucher"></detail-voucher>
       <modal-open-cc ref="modalOpenCc"></modal-open-cc>
      <Footer />
    </div>
</template>

<script>
  import Header from '../components/Header';
  import Footer from '../components/Footer';
  import DatePicker from '../components/DatePicker';
  import CardPaymentMethod from '../components/CardPaymentMethod';
  import Vue from 'vue'
  import L from 'leaflet';
  import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';

  export default {
    name: 'CheckoutRental',
    components: {
      Header,
      Footer,
      DatePicker,
      CardPaymentMethod,
      LMap,
      LTileLayer,
      LMarker,
      ModalPin: () => import('@/components/layout/ModalPin.vue'),
      CancelationPolicyDialog: () => import('@/components/layout/CancelationPolicyDialog.vue'),
      DetailVoucher: () => import('@/components/layout/DetailVoucher.vue'),
      ModalOpenCc: () => import("@/components/layout/ModalOpenCc.vue"),
    },
    data: () => ({
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomSnap: 0.5
      },
      activeTab: 0,
      selectedIdWilayah: null,
      showPembayaran: 'subtotal',
      listKota: [],
      listDock: [],
      rekomendasiVoucher: [],
      listVoucher: [],
      kodeVoucher: '',
      statusMerchant: false,
      formData: {
          pin: null,
          tokenPayment: '',
          link: '',
          totalQty:0,
          subtotal: 0,
          tripwepoints: 0,
          bookingDate: null,
          paymentMode: null,
          voucher: {},
          responseCreateTagihan: {}
      },
      iconCC: require('@/assets/cc-icon.png'),
      iconBni: require('@/assets/bni-icon.png'),
      iconBca: require('@/assets/bca-icon.png'),
      iconBri: require('@/assets/bri-icon.png'),
      iconPermata: require('@/assets/permata-icon.png'),
      iconTripwe: require('@/assets/tripwe-icon.png'),
      iconBalance: require('@/assets/logo-balance.png'),
      iconOther: require('@/assets/logo-other-payment.png'),
      iconVoucher: require('@/assets/icons/icon-voucher.png'),
      voucherBg: require('@/assets/voucher.png'),
      voucherStyle:{
          backgroundImage: "url('/voucher.png')",
          backgroundSize: "100% 140%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
      },
      voucherGreenStyle:{
          backgroundImage: "url('/voucher_green.png')",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
      },
    }),
    created () {
        this.loadCity();
        this.loadVoucher();
        this.countVoucher();
        this.loadRekomenVoucher();
        this.checkStatusMerchant();
        this.selectedIdWilayah = this.$store.state.idWilayah;
        var now = this.$moment();
        this.formData.tokenPayment = "TWIP" + now.format("YYYYMMDDHHmmss") + this.randomStr(1,9999);
    },
    computed: {
        isLimitMaxCC(){
            if(this.totalpayment > 100000000){
                return true;
            }
            return false;
        },
        ttlVocer(){
            return this.listVoucher.length;
        },
        durasiBooking(){
            var st = this.$moment(this.$store.state.bookRental.jam_awal);
            var en = this.$moment(this.$store.state.bookRental.jam_akhir);
            return en.diff(st, 'hours', true);
        },
        potonganVoucher(){
            var potongan = 0;
            if(Object.keys(this.formData.voucher) != 0){
                if(this.formData.voucher.satuan == 'PERSEN'){
                    potongan = this.subtotal * this.formData.voucher.nominal/100;
                    if(potongan > parseInt(this.formData.voucher.maximal,10)){
                        potongan = parseInt(this.formData.voucher.maximal,10);
                    }
                }else{
                    potongan = this.formData.voucher.nominal;
                }
            }
            return potongan;
        },
        tripwepayVoucherCashback(){
            var potongan = 0;
            var twpay = 0;
            if(Object.keys(this.formData.voucher) != 0){
                if(this.formData.voucher.status_cashback == '1'){
                    if(this.formData.voucher.satuan == 'PERSEN'){
                        potongan = this.subtotal * this.formData.voucher.nominal/100;
                        if(potongan > parseInt(this.formData.voucher.maximal,10)){
                            potongan = parseInt(this.formData.voucher.maximal,10);
                        }
                        var hasilpersen = potongan * this.formData.voucher.persencashback/100;
                        twpay = hasilpersen;
                    }else{
                        potongan = this.formData.voucher.nominal;
                        var hasilpersen = potongan * this.formData.voucher.persencashback/100;
                        twpay = hasilpersen;
                    }
                }
            }
            return twpay;
        },
        pointVoucherCashback(){
            var potongan = 0;
            var point = 0;
            if(Object.keys(this.formData.voucher) != 0){
                if(this.formData.voucher.status_cashback == '1'){
                    if(this.formData.voucher.satuan == 'PERSEN'){
                        potongan = this.subtotal * this.formData.voucher.nominal/100;
                        if(potongan > parseInt(this.formData.voucher.maximal,10)){
                            potongan = parseInt(this.formData.voucher.maximal,10);
                        }
                        var hasilpersen = potongan * this.formData.voucher.persencashback/100;
                        point = potongan-hasilpersen;
                    }else{
                        potongan = this.formData.voucher.nominal;
                        var hasilpersen = potongan * this.formData.voucher.persencashback/100;
                        point = potongan-hasilpersen;
                    }
                }
            }
            return point;
        },
        subtotal(){
            var subttl = 0;
            for (var x in this.$store.state.packageList) {
                if (Object.hasOwnProperty.call(this.$store.state.packageList, x)) {
                    const element = this.$store.state.packageList[x];
                    subttl += parseInt(element.qty,10) * element.harga * this.durasiBooking;
                }
            }
            return subttl;
        },
        crediCardAdminFee(){
            var total = this.subtotal - this.potonganVoucher;
            if(this.formData.paymentMode == 1){
                return (total * 3.5/100);
            }
            return 0;
        },
        totalpayment(){
            if(this.formData.voucher.status_cashback == "1"){
                return this.subtotal + this.crediCardAdminFee;
            }else{
                return this.subtotal - this.potonganVoucher + this.crediCardAdminFee;
            }
        },
        totalQty(){
            var totalQty = 0;
            for (var x in this.$store.state.packageList) {
                if (Object.hasOwnProperty.call(this.$store.state.packageList, x)) {
                    const element = this.$store.state.packageList[x];
                    totalQty += parseInt(element.qty,10);
                }
            }
            this.formData.totalQty = totalQty;
            return totalQty;
        }
    },
    methods: {
        async showDetailVoucher(detail){
            var res = await this.$refs.detailVoucher.open({data: detail});
            if(res){
                this.selectVoucher(detail);
            }
        },
        addVoucher(){
            var filteredVoucher = this.listVoucher.filter(val => val.kode_voucher == this.kodeVoucher);
            if(filteredVoucher.length == 1){
                this.selectVoucher(filteredVoucher[0]);
                this.kodeVoucher = '';
            }else{
                this.$toasted.global.error("Voucher not found");
            }
        },
        selectVoucher(vocer){
            this.formData.voucher = vocer;
            this.$toasted.global.success("Voucher applied");
            this.showPembayaran = 'subtotal';
        },
        async checkStatusMerchant() {
            const res = await this.$http.post( `${this.$apiTripweWeb}/config/checkKhusus`, {
                    "idtoko"        : this.$store.state.bookRental.toko,
            });
            if(res.data.status == "200"){
                this.statusMerchant = res.data.response;
            }
        },
        async loadVoucher() {            
            const res = await this.$http.post( `${this.$apiTripweWeb}/voucher/list`, {
                params: {
                    "store"          : this.$store.state.bookRental.toko,
                    "category"      : "1",
                    "price"         : this.totalpayment
                }
            });
            if(res.data.status == 200){
                this.listVoucher = res.data.data;
            }
        },
        async loadRekomenVoucher() {
            const res = await this.$http.post( `${this.$apiTripweWeb}/voucher/list/recommended`, {
                params: {
                    "store"          : this.$store.state.bookRental.toko,
                    "category"      : "1",
                    "price"         : this.totalpayment
                }
            });
            if(res.data.status == 200){
                this.rekomendasiVoucher = res.data.data;
            }
        },
        async countVoucher() {
            // await Vue.axios({
            //     method: "post",
            //     url: `${this.$apiSaeboTWV2}/ListOrderV3`,
            //     data: {
            //         "id": "UATVCREDEEMCOUNT",
            //         "kodecustomer" : this.$store.state.user.id_user,
            //         "toko"         : this.$store.state.bookRental.toko,
            //         "status"       : "1",
            //         "hargamin"     : this.totalpayment,
            //         "limit"        : "",
            //         "cari"         : ""
            //     },
            // })
            // .then(response => {
            //     this.ttlVocer = response.data[0].CONT;
            //     return;
            // });
        },
        async handleCancelation(){
            await this.$refs.cancelationPolicyDialog.open({
                title: 'Cancelation Policy',
                button: 'Close'
            });
        },
        randomStr(min, max){
            return Math. floor(Math. random()*(max-min+1)+min);
        },
        selectPayment(val){
            this.formData.paymentMode = val;
        },
        async handleBook(){
            if(this.$store.state.bookRental.bookingDate == null || this.$store.state.bookRental.bookingDate == ""){
                this.$toasted.global.error("Please select date first");
                return;
            }
            if(this.formData.paymentMode == null || this.formData.paymentMode == ""){
                this.$toasted.global.error("Please select payment mode first");
                return;
            }
            if(this.formData.paymentMode == 1 && (this.$store.state.user.email == null || this.$store.state.user.email == "")){
                this.$toasted.global.error("Add email address first");
                return;
            }
            if(this.formData.paymentMode == 1 && this.totalpayment > 100000000){
                this.$toasted.global.error("The maximum payment using a credit card is 100 million");
                return;
            }
            if(this.formData.paymentMode == 2){
                // var valid = await this.validasiSaldo();
                // if(!valid){
                //     return;
                // }
                const confirmed = await this.$refs.modalPin.open({
                    button: 'OK'
                });
                if(confirmed != null){
                    this.formData.pin = confirmed;
                    this.createInvoice();
                }
                return;
            }
            this.createInvoice();
            return;
        },
        loadCity: function () {
            var bodyFormData = new FormData();
            bodyFormData.append('txtLat', '');
            bodyFormData.append('txtLong', '');
            bodyFormData.append('txtLimit', '50');
            bodyFormData.append('txtFilter', 'a');
            bodyFormData.append('txtJenis', 'r');

            Vue.axios({
                method: "post",
                url: "https://tripwe.id/ic/jetski-android-new/list_wilayah_terdekat_v19.php",
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(response => {
                this.listKota = response.data;
                // if(this.listKota.length > 0){
                //     this.idWilayah = this.listKota[0].id_wilayah;
                // }
                // this.loadDock();
                return;
            });
        },
        loadDock: function () {
            var bodyFormData = new FormData();
            bodyFormData.append('txtStatus', 0);
            bodyFormData.append('txtIdWilayah', this.idWilayah);

            Vue.axios({
                method: "post",
                url: "https://tripwe.id/ic/jetski-android-new/list_dermaga_status.php",
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(response => {
                this.listDock = response.data;
                return;
            });
        },
        async validasiSaldo(){
            this.$store.commit('setOverlayLoading', true);
            var resSaldo = await this.$http.get(`${this.$apiTripweWeb}/tripwepay/balance`);
            
            this.$store.commit('setOverlayLoading', false);
            this.$store.commit('setSaldo', resSaldo.data.response);  
            if(this.$store.state.saldo.SaldoAktif < this.totalpayment){
                this.$toasted.global.error("TripwePay amount less than total payment");
                this.$router.push({ 
                    name: 'Topup'
                });
                return false;
            }
            return true;
        },
        async createInvoice(){
            this.$store.commit('setOverlayLoading', true);
            var curDate = new Date();
            var nobukti = "JR" + this.$moment(curDate).format("YYMMDD") + '' + Math.floor(Math.random() * 100);
            
            var jam_awal_f = this.$moment(this.$store.state.bookRental.jam_awal);
            var jam_akhir_f = this.$moment(this.$store.state.bookRental.jam_akhir);
            
            var daftarpesanan = '';
            var arrpesanan = [];
            for (const key in this.$store.state.packageList) {
                if (Object.hasOwnProperty.call(this.$store.state.packageList, key)) {
                    const element = this.$store.state.packageList[key];
                    if(element.qty > 0){
                        var curpsn = '{"id_paket":'+ '"' + element.id + '"' + ',"seat":'+ '"' + element.jumlah_kursi + '"' +',"qtyunit":'+ '"' + element.qty + '"' +'}';
                        arrpesanan.push(curpsn);
                    }
                }
            }
            daftarpesanan = arrpesanan.join(",");
            try {
                var datapost = {
                    "nobukti"      : nobukti,
                    "id_paket"     : '',
                    "kodecustomer" : this.$store.state.user.id_user,
                    "vauser"       : this.$store.state.user.va,
                    "toko"         : this.$store.state.bookRental.toko,
                    "vatoko"       : this.$store.state.bookRental.selectedToko.va,
                    "tgl"          : this.$moment(this.$store.state.bookRental.bookingDate).format('YYYY-MM-DD'),
                    "tgl_akhir"    : this.$moment(this.$store.state.bookRental.bookingDate).format('YYYY-MM-DD'),
                    "jam_awal"     : jam_awal_f.format("HH:mm"),
                    "jam_akhir"    : jam_akhir_f.format("HH:mm"),
                    "qtyjam"       : this.durasiBooking,
                    "voucher"      : this.formData.voucher.id_voucher,
                    "cc"           : this.formData.paymentMode,
                    "pin"          : this.formData.pin,
                    "daftarpesan"  : '[' + daftarpesanan + ']'
                };
                
                const response = await this.$http.post(`${this.$apiTripweWebV2}/transactions/create`, datapost);
                
                var res = response.data;
                if(res.status== '200'){
                    this.formData.responseCreateTagihan = res.response;
                    this.formData.link = '';
                    // if(this.formData.paymentMode == 2){
                    //     await this.payC2Chold();
                    // }else 
                    if(this.formData.paymentMode == 1){
                        this.formData.link = res.data.redirect_url;
                        this.$store.commit("setOverlayLoading", false);
                        await this.$refs.modalOpenCc.open({ link: this.formData.link });
                        // this.handleCC();
                        this.$router.replace({ 
                            name: 'TransactionDetail',
                            params: {
                                nobukti: res.data.reference_no,
                            } 
                        });
                    }else{
                        this.$router.replace({ 
                            name: 'TransactionDetail',
                            params: {
                                nobukti: res.data.reference_no,
                            } 
                        });
                    }
                } else if(res.status == '40001'){
                    this.handleBook();
                    throw res.message;
                } else if(res.status == '40002'){
                    this.$router.push({
                        name: "Topup",
                    });
                    throw res.message;
                }else{
                    throw res.message;
                }
            } catch (error) {
                this.$toasted.global.error(error);
            } finally {
                this.$store.commit('setOverlayLoading', false);
            }
            
        },
        async handleCC(){
            // var nWdw = window.open(this.formData.link, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
            var nWdw = window.open(this.formData.link, '_blank').focus();
            // this.$router.push({ 
            //     name: 'PageFrame',
            //     params: {
            //         formData: this.formData
            //     } 
            // });
        },
        async payC2Chold(){
            try {
                this.$store.commit('setOverlayLoading', true);
                var datapost = {
                    "customer_name"   : this.$store.state.user?.nama ?? '',
                    "payment_amount"  : this.formData.responseCreateTagihan?.TOTALBAYAR ?? 0,
                    "virtual_account" : this.$store.state.bookRental.selectedToko.va,         // VA Tenant
                    "trx_id"          : this.formData.responseCreateTagihan?.KODE ?? '',
                    "description"     : 'Payment',
                    "vapermata"       : this.$store.state.user.va,                // VA User
                    "token"           : this.formData.tokenPayment,
                    "app"             : "TRIPWEDEV",
                    "client_id"       : "WEBAPPS"
                };
                    
                // var res = await this.$http.post('https://trip.saebo.id/TWPRDv2/api/CtoCTagihanHoldv4/Dev', datapost);
                var res = await this.$http.post(`${this.$apiTripweWeb}/tagihan/hold`, datapost);
                if(res.data.status == '200'){
                    var resSaldo = await this.$http.get(`${this.$apiTripweWeb}/tripwepay/balance`);
                    
                    this.$store.commit('setSaldo', resSaldo.data.response);
                    this.$router.replace({ 
                        name: 'TransactionDetail',
                        params: {
                            nobukti: this.formData.responseCreateTagihan?.KODE,
                        } 
                    });
                }else{
                    throw res.data.message;
                }
            } catch (error) {
                this.$toasted.global.error(error);
            } finally {
                this.$store.commit('setOverlayLoading', false);
            }
        }
    }
  }
</script>